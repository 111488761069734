import { Commit, Dispatch } from 'vuex'
import { LRUCache } from '@splunkdlt/cache'
import { State } from './index'
import {
  ExtendedTx,
  StrippedBlock,
  isStrippedBlock,
  ExtendedInput,
  BlockSummary,
  isExtendedTx,
  isExtendedCoinbase,
  SimpleAggregatedTransaction,
  TxHeader
} from '@/types/bitcoin'
import { ClusteredCounterparty, FormattedBlock, FormattedLogEvent, FormattedTransaction, isFormattedTransactions } from '@/types/eth'
import {
  Api,
  AttributionMap,
  BlocksRequest,
  ComparisonConstraints,
  EntitySummary,
  EntityType,
  failedResponse,
  FindInputRequest,
  ItemRequest,
  ItemsRequest,
  NetworkRequest,
  SimplifiedBitcoinTransaction,
  SortMap,
  SupportedAsset,
  TxnsBlockPage,
  TxnsBlockPageRequest,
  TxnTriplet
} from '@/utils/api'
import { track } from '@/utils/tracking'
import { AggregatedTypedCounterparty } from './investigations/tabular'
import { addBigNum } from '@/utils/bignum'
import { filter } from '@/utils/filters'
import { BlockCondensed, Transaction, TransactionInfo } from '@/types/tron'
import { nodeTypeToIdString } from '@/utils/viz'

export interface LatestBlocks {
  [key: string]: ChainBlock
}

export interface TransactionsByHash {
  [key: string]: ChainTransaction
}

export interface OutputReference {
  txid: string
  n: number
}

export interface NetworkSupportedAsset extends SupportedAsset {
  network: string
}

export interface NestedStringNumberMap {
  [key: string]: string | number | NestedStringNumberMap
}

export interface NftMetadata {
  name: string
  image: string
  [key: string]: string | number | NestedStringNumberMap
}

export interface InputMap {
  [key: string]: ExtendedInput
}

export interface BtcHerustics {
  id: string
  isOutput: boolean
  index: number
  address: string
  addressType: string
  time: number
  svb: number
  fee: number
  rbf: boolean
  cluster: string
}

export interface TxnCounterpartiesMap {
  [key: string]: AggregatedTypedCounterparty[]
}

export type ChainTransaction = ExtendedTx | FormattedTransaction | Transaction

export type ChainBlock = FormattedBlock | StrippedBlock | BlockCondensed

export type ChainReceipt = FormattedLogEvent | TransactionInfo

export function isFormattedLogEvent(receipt: ChainReceipt): receipt is FormattedLogEvent {
  return (receipt as FormattedLogEvent).logIndex != null
}

export function isTransactionInfo(receipt: ChainReceipt): receipt is TransactionInfo {
  return (receipt as TransactionInfo).id != null
}

export const chainState = {
  supportedNetworks: <string[]>['ethereum', 'bitcoin', 'tron'],
  ethereumNetworks: <string[]>['ethereum', 'polygon', 'arbitrum', 'fantom'],
  bitcoinNetworks: <string[]>['bitcoin'],
  tronNetworks: <string[]>['tron'],
  latestBlocks: <LatestBlocks>{},
  latestBlockEthereum: <FormattedBlock | undefined>undefined,
  latestBlockBitcoin: <StrippedBlock | undefined>undefined,
  latestBlockTron: <BlockCondensed | undefined>undefined,
  block: <ChainBlock | undefined>undefined,
  blocks: <ChainBlock[] | BlockSummary[]>[],
  transaction: <ChainTransaction | undefined>undefined,
  clusterSummaries: new LRUCache<string, EntitySummary>({ maxSize: 20 }),
  clusterSummariesUpdated: <number>0,
  transactions: <ChainTransaction[] | string[]>[],
  transactionsPage: <ChainTransaction[] | string[]>[],
  transactionsBlockPage: <TxnsBlockPage | undefined>undefined,
  receipts: <ChainReceipt[]>[],
  receiptsCache: new LRUCache<string, ChainReceipt>({ maxSize: 2000 }),
  receiptsCacheCount: <number>0,
  transactionsCache: new LRUCache<string, ChainTransaction>({ maxSize: 2000 }),
  transactionsCacheCount: <number>0,
  foundInputs: new LRUCache<string, ExtendedInput>({ maxSize: 2000 }),
  foundInputsCount: <number>0,
  heuristicsCache: new LRUCache<string, BtcHerustics>({ maxSize: 2000 }),
  heuristicsCacheCount: <number>0,
  txnHeadersCache: new LRUCache<string, TxHeader>({ maxSize: 2000 }),
  txnHeadersCacheCount: <number>0,
  unspentOutputs: <OutputReference[]>[],
  tokenMetadata: <NftMetadata | undefined>undefined,
  ipfsGateway: 'https://gateway.pinata.cloud/ipfs/',
  statsSupportedNetworks: <NetworkSupportedAsset[]>[],
  entityLedgerSimple: <SimpleAggregatedTransaction[]>[],
  entityLedgerFull: <{ [key: string]: ChainTransaction }>{},
  entityLedgerCount: <number>0,
  txnCounterpartiesMap: <TxnCounterpartiesMap | undefined>undefined,
  blockSummary: new LRUCache<string, BlockSummary>({ maxSize: 1000 }),
  chainAttributions: <AttributionMap>{}
}

export const chainMutations = {
  // blocks
  SET_LATEST_BLOCK(state: State, { network, block }: { network: string; block: ChainBlock }) {
    if (network === 'bitcoin') {
      state.latestBlockBitcoin = block as StrippedBlock
    }
    if (network === 'ethereum') {
      state.latestBlockEthereum = block as FormattedBlock
    }
    if (network === 'tron') {
      state.latestBlockTron = block as BlockCondensed
    }
    // console.log(network, block)
    // state.latestBlocks[network] = block
  },
  SET_BLOCK(state: State, { block }: { block: ChainBlock }) {
    state.block = block
  },
  SET_BLOCKS(state: State, { blocks }: { blocks: ChainBlock[] }) {
    state.blocks = blocks
  },
  SET_BLOCK_SUMMARY(state: State, { block }: { block: BlockSummary }) {
    state.blockSummary.set(block.hash, block)
  },
  // transactions
  SET_TRANSACTION(state: State, { transaction }: { transaction?: ChainTransaction }) {
    state.transaction = transaction
  },
  SET_CLUSTER_SUMMARY(state: State, { id, summary }: { id: string; summary: EntitySummary }) {
    state.clusterSummaries.set(id, summary)
  },
  CLUSTER_SUMMARIES_UPDATED(state: State) {
    state.clusterSummariesUpdated++
  },
  SET_TRANSACTIONS(state: State, { transactions }: { transactions: ChainTransaction[] | string[] }) {
    state.transactions = transactions
  },
  SET_TRANSACTION_PAGE(
    state: State,
    { transactions }: { transactions: ChainTransaction[] | string[] }
  ) {
    state.transactionsPage = transactions
  },
  SET_TRANSACTIONS_BLOCK_PAGE(state: State, { txnsBlockPage }: { txnsBlockPage: TxnsBlockPage | undefined }) {
    state.transactionsBlockPage = txnsBlockPage
  },
  SET_TRANSACTION_AT_INDEX(
    state: State,
    { index, transaction }: { index: number; transaction: ChainTransaction }
  ) {
    state.transactions[index] = transaction
  },
  SET_ENTITY_LEDGER_SIMPLE(state: State, { simple }: { simple: SimpleAggregatedTransaction[] }) {
    state.entityLedgerSimple = simple
  },
  SET_ENTITY_LEDGER_FULL(state: State, { full }: { full: { [key: string]: ChainTransaction } }) {
    state.entityLedgerFull = full
  },
  SET_ENTITY_LEDGER_COUNT(state: State, { count }: { count: number }) {
    state.entityLedgerCount = count
  },
  SET_TXN_COUNTERPARTIES_MAP(state: State, { map }: { map?: TxnCounterpartiesMap }) {
    state.txnCounterpartiesMap = map
  },
  // receipts
  SET_RECEIPTS(state: State, { receipts }: { receipts: ChainReceipt[] }) {
    state.receipts = receipts
  },
  SET_RECEIPTS_CACHE(state: State, { id, receipt }: { id: string; receipt: ChainReceipt }) {
    state.receiptsCache.set(id, receipt)
    state.receiptsCacheCount++
  },
  SET_TRANSACTIONS_CACHE(state: State, { id, network, txn }: { id: string; network: string; txn: ChainTransaction }) {
    state.transactionsCache.set(`${network}${id}`, txn)
    state.transactionsCacheCount++
  },
  SET_FOUND_INPUT(state: State, { input, ref }: { input: ExtendedInput; ref: OutputReference }) {
    state.foundInputs.set(`${ref.txid}_${ref.n}`, input)
    state.foundInputsCount++
  },
  SET_HEURISTICS_CACHE(state: State, { txns }: { txns: BtcHerustics[] }) {
    txns.forEach((txn) => {
      state.heuristicsCache.set(txn.id, txn)
    })
    state.heuristicsCacheCount++
  },
  SET_TXN_HEADERS_CACHE(state: State, { txns }: { txns: TxHeader[] | FormattedTransaction[] }) {
    // we don't need this for evm right now
    if (!isFormattedTransactions(txns)) {
      txns.forEach((txn) => {
        state.txnHeadersCache.set(txn.txid, txn)
      })
      state.txnHeadersCacheCount++
    }
  },
  ADD_UNSPENT_OUTPUT(state: State, { ref }: { ref: OutputReference }) {
    state.unspentOutputs = [...state.unspentOutputs, ref]
  },
  // token metadata
  SET_TOKEN_METADATA(state: State, { metadata }: { metadata: NftMetadata }) {
    state.tokenMetadata = metadata
  },
  // stats
  SET_STATS_SUPPORTED_NETWORKS(state: State, { assets }: { assets: NetworkSupportedAsset[] }) {
    // state.statsSupportedNetworks = [...state.statsSupportedNetworks, ...assets]
    state.statsSupportedNetworks = assets
  },
  // attributions
  SET_CHAIN_ATTRIBUTIONS(state: State, attributions: AttributionMap) {
    state.chainAttributions = attributions
  }
}

export function formatNftImageUrl(ipfsGateway: string, m: NftMetadata): any {
  if (m.image && m.image.startsWith('ipfs://')) {
    m.image = m.image.replace('ipfs://', ipfsGateway)
  }
  return m
}

export const chainActions = (state: State, api: Api, dispatch: Dispatch) => ({
  // blocks
  async getLatestBlock({ commit }: { commit: Commit }, { network }: { network: string }) {
    const response = await api.getLatestBlock({ network })
    if (response) {
      commit('SET_LATEST_BLOCK', { network, block: response })
      const height = isStrippedBlock(response) ? response.height : response.number
      if (height) {
        const blocks = await api.getBlockSummaries({
          network,
          type: 'height',
          from: height - 10,
          to: height,
          count: 10,
          page: 0
        })
        commit('SET_BLOCKS', { blocks })
      }
    }
  },
  async getBlock({ commit }: { commit: Commit }, { network, id }: ItemRequest) {
    const response = await api.getBlock({ network, id })
    if (response != null) {
      track('getBlock', { network, id })
      commit('SET_BLOCK', { block: response })
    }
  },
  resetBlock({ commit }: { commit: Commit }) {
    commit('SET_BLOCK', { block: undefined })
  },
  async getBlocks({ commit }: { commit: Commit }, { network, type, from, to, count, page }: BlocksRequest) {
    const response = await api.getBlockSummaries({ network, type, from, to, count, page })
    if (response != null) {
      track('getBlocks', { network, type, from, to, count, page })
      commit('SET_BLOCKS', { blocks: response })
    }
  },
  async getBlockSummary({ commit }: { commit: Commit }, { network, id }: ItemRequest) {
    const response = await api.getBlockSummary({ network, id })
    if (response != null) {
      track('getBlockSummary', { network, id })
      commit('SET_BLOCK_SUMMARY', { block: response })
    }
  },
  resetBlocks({ commit }: { commit: Commit }) {
    commit('SET_BLOCKS', { blocks: [] })
  },
  // transactions
  async getTransaction({ commit }: { commit: Commit }, { network, id }: ItemRequest) {
    const transaction = await api.getTransaction({ network, id })

    if (transaction != null) {
      track('getTransaction', { network, id })
      // check for existing addresses in cache
      const notInClusterCache: string[] = []
      const notInAttributionCache: string[] = []
      if (isExtendedTx(transaction)) {
        for (const input of transaction.vin) {
          if (!isExtendedCoinbase(input)) {
            const { address } = input.spentOutput
            if (address != null && address !== '') {
              // cluster cache (use get instead of has so that it doesn't get evicted when we add new data)
              if (state.shared.clusterAddressCache.get(address) == null) {
                notInClusterCache.push(address)
              }
              // attribution cache (use get instead of has so that it doesn't get evicted when we add new data)
              if (state.shared.attributionsCache.get(address) == null) {
                notInAttributionCache.push(address)
              }
            }
          }
        }
        for (const output of transaction.vout) {
          const { address } = output.scriptPubKey
          if (address != null && address !== '') {
            if (state.shared.clusterAddressCache.get(address) == null) {
              notInClusterCache.push(address)
            }
            if (state.shared.attributionsCache.get(address) == null) {
              notInAttributionCache.push(address)
            }
          }
        }
      } else {
        // pull inputs and outputs from hbase, since it's not always in the transaction data
        const transactions = await api.getTransactionLedger({
          network,
          id,
          page: 1,
          perPage: 100,
          sorts: { isOutput: 'desc' },
          simple: true
        })
        if (!failedResponse(transactions) && transactions != null) {
          const counterparties: ClusteredCounterparty[] = (transactions.ledger as SimplifiedBitcoinTransaction[]).map(t => ({
            address: t.address,
            cluster: t.cluster ?? undefined,
            clusterAttribution: t.clusterAttribution ?? undefined,
            value: t.amount,
            symbol: t.symbol,
            isOutput: t.isOutput
          }))
          commit('SET_COUNTERPARTIES', { counterparties, expansion: false })
        }
      }
      // request addresses not in cache
      if (notInClusterCache.length > 0) {
        const addressClusters = await api.getClustersForAddresses({ network, ids: notInClusterCache })
        if (addressClusters != null) {
          const addresses = Object.keys(addressClusters)
          for (const address of addresses) {
            const cluster = addressClusters[address]
            commit('CLUSTER_CACHE_ADD', { address, cluster })
          }
        }
      }
      if (notInAttributionCache.length > 0) {
        const addressAttributions = await api.getAttributions(notInAttributionCache)
        if (addressAttributions != null) {
          const addresses = Object.keys(addressAttributions)
          const attributions: AttributionMap = {}
          for (const address of addresses) {
            attributions[address] = addressAttributions[address]
          }
          commit('ATTRIBUTIONS_CACHE_ADD', { attributions })
        }
      }
      commit('SET_TRANSACTION', { transaction })
    }
  },
  async getClusterSummary({ commit }: { commit: Commit }, { network, id }: { network: string; id: string }) {
    if (state.clusterSummaries.get(id) == null) {
      const entity = nodeTypeToIdString({ id, type: 'cluster', network })
      let summary = state.shared.summariesCache.get(entity)
      if (summary == null) {
        const summaryResponse = await api.getSummary({ id, idType: 'cluster', network })
        if (failedResponse(summaryResponse)) {
          dispatch('updateSnackbar', {
            show: state.enableErrors,
            text: `cluster summary fetch failed: ${summaryResponse.message} ${JSON.stringify(summaryResponse.original)}`,
            timeout: -1
          })
        } else if (summaryResponse != null) {
          summary = summaryResponse.summary
          commit('ADD_ENTITY_SUMMARY_CACHE', { entity, summary })
        }
      }
      commit('SET_CLUSTER_SUMMARY', { id, summary })
    }

    commit('CLUSTER_SUMMARIES_UPDATED')
  },
  async getTransactions({ commit }: { commit: Commit }, { network, id }: ItemRequest) {
    const response = await api.getTransactions({ network, id })
    if (response != null) {
      track('getTransactions', { network, id })
      if (!isExtendedTx(response[0])) {
        const receipts = await api.getReceiptsForBlock({ network, id })
        if (receipts) {
          commit('SET_RECEIPTS', { receipts })
        }
      }
      commit('SET_TRANSACTIONS', { transactions: response })
    }
  },
  async setTransactionPage(
    { commit }: { commit: Commit },
    { transactions }: { transactions: ChainTransaction[] | string[] }
  ) {
    commit('SET_TRANSACTION_PAGE', { transactions })
  },
  async getTransactionsListPage(
    { commit }: { commit: Commit },
    { network, list }: { network: string; list: string[] }
  ) {
    const response = await api.getTransactionsList({ network, list })
    if (response != null) {
      track('getTransactionsListPage', { network, list })
      commit('SET_TRANSACTION_PAGE', { transactions: response })
    }
  },
  async getTransactionsBlockPage({ commit }: { commit: Commit }, pageReq: TxnsBlockPageRequest) {
    const response = await api.getTransactionsBlockPage(pageReq)
    if (response != null) {
      const { network, id } = pageReq
      track('getTransactionsBlockPage', { network, id })
      commit('SET_TRANSACTIONS_BLOCK_PAGE', { txnsBlockPage: response })
    }
  },
  async setTransactionAtIndex(
    { commit }: { commit: Commit },
    { transaction, index }: { transaction: ChainTransaction; index: number }
  ) {
    commit('SET_TRANSACTION_AT_INDEX', { transaction, index })
  },
  async resetTransaction({ commit }: { commit: Commit }) {
    commit('SET_RECEIPTS', { receipts: [] })
    commit('SET_TRANSACTION', { transaction: undefined })
    commit('SET_TOKEN_METADATA', { metadata: undefined })
  },
  async resetTransactions({ commit }: { commit: Commit }) {
    commit('SET_RECEIPTS', { receipts: [] })
    commit('SET_TRANSACTIONS', { transactions: [] })
    commit('SET_TRANSACTION_PAGE', { transactions: [] })
    commit('SET_TRANSACTIONS_BLOCK_PAGE', { txnsBlockPage: undefined })
  },
  // addresses
  async resetEntity({ commit }: { commit: Commit }) {
    commit('SET_ADDRESS_CLUSTER', {})
    commit('SET_ENTITY_SUMMARY', undefined)
    commit('SET_ENTITY_LEDGER_SIMPLE', { simple: [] })
    commit('SET_ENTITY_LEDGER_FULL', { full: {} })
    commit('SET_ENTITY_LEDGER_COUNT', { count: 0 })
    commit('SET_TXN_COUNTERPARTIES_MAP', {})
  },
  async getEntity(
    { commit }: { commit: Commit },
    {
      network,
      id,
      page,
      perPage,
      sorts,
      amountConstraint,
      timeConstraint,
      inputs,
      outputs,
      type
    }: {
      network: string
      id?: string
      page: number
      perPage: number
      sorts: SortMap
      amountConstraint: ComparisonConstraints
      timeConstraint: ComparisonConstraints
      inputs: boolean
      outputs: boolean
      type?: EntityType
    }
  ) {
    if (type != null && type !== 'attribution') {
      // get cluster
      dispatch('getClusterForAddress', { network, address: id })
    }
    if (id != null) {
      // get summary
      dispatch('getEntitySummary', { id, type, network })
    }
    // get ledger
    let response
    if (id == null) {
      response = await api.getFilteredLedger({
        network,
        page,
        perPage,
        sorts,
        txnData: true,
        fullTxns: true,
        amountConstraint,
        timeConstraint,
        aggregated: false
      })
    } else if (type === 'address') {
      response = await api.getAddressLedger({
        network,
        id,
        page,
        perPage,
        sorts,
        txnData: true,
        fullTxns: true,
        amountConstraint,
        timeConstraint,
        aggregated: true
      })
    } else {
      response = await api.getClusterLedger({
        network,
        id,
        page,
        perPage,
        sorts,
        txnData: true,
        ioData: false,
        fullTxns: true,
        amountConstraint,
        timeConstraint,
        attribution: type === 'attribution',
        aggregated: true
      })
    }
    if (failedResponse(response)) {
      track('getEntityFailed', { network, id, type })
      dispatch('updateSnackbar', {
        show: state.enableErrors,
        text: `${response.message} ${JSON.stringify(response.original)}`,
        timeout: -1
      })
      dispatch('resetEntity')
      return
    }
    if (response != null) {
      track('getEntity', { network, id, type })
      let simple = response.simple
      if (id == null) {
        const addresses = new Set(simple.map(txn => txn.address))
        await dispatch('getTargetAttributions', { network, addresses })
      }
      const txids = Array.from(new Set(simple.map(txn => txn.id)))
      if (!(inputs && outputs)) {
        simple = filter(simple, txn => (txn.isOutput && outputs) || (!txn.isOutput && inputs))
      }
      commit('SET_ENTITY_LEDGER_SIMPLE', { simple })
      commit('SET_ENTITY_LEDGER_FULL', { full: response.full })
      commit('SET_ENTITY_LEDGER_COUNT', { count: response.total })

      dispatch('cacheTxnHeaders', { network, txids })
      dispatch('aggregateCounterparties', { network })
    }
  },
  async getEntityLedgerCount(
    { commit }: { commit: Commit },
    {
      network,
      id,
      amountConstraint,
      timeConstraint,
      stream,
      type
    }: {
      network: string
      id?: string
      amountConstraint: ComparisonConstraints
      timeConstraint: ComparisonConstraints
      stream: boolean
      type?: EntityType
    }
  ) {
    const streamTo = state.serverEventsId
    if (stream) {
      if (id == null) {
        api.getFilteredLedgerCount({ network, amountConstraint, timeConstraint, aggregated: false, streamTo })
      } else if (type === 'address') {
        api.getAddressLedgerCount({ network, id, amountConstraint, timeConstraint, aggregated: true, streamTo })
      } else {
        api.getClusterLedgerCount({
          network,
          id,
          amountConstraint,
          timeConstraint,
          aggregated: true,
          streamTo,
          attribution: type === 'attribution'
        })
      }
    } else {
      if (id == null) {
        await api.getFilteredLedgerCount({ network, amountConstraint, timeConstraint, aggregated: false })
      } else if (type === 'address') {
        await api.getAddressLedgerCount({ network, id, amountConstraint, timeConstraint, aggregated: true })
      } else {
        await api.getClusterLedgerCount({
          network,
          id,
          amountConstraint,
          timeConstraint,
          aggregated: true,
          attribution: type === 'attribution'
        })
      }
    }
  },
  async aggregateCounterparties({ commit }: { commit: Commit }, { network }: { network: string }) {
    const addresses = new Set(state.entityLedgerSimple.flatMap(txn => txn.counterparties.map(cp => cp.address)))
    await dispatch('getTargetClusters', {
      network,
      addresses
    })
    const txnCounterpartiesMap: TxnCounterpartiesMap = {}
    for (const txn of state.entityLedgerSimple) {
      const counterpartiesAgg: {
        [entity: string]: {
          type: 'cluster' | 'address'
          attribution?: string
          value: string | number
        }
      } = {}
      const { id, isOutput, counterparties } = txn
      for (const counterparty of counterparties) {
        const { address, value } = counterparty
        const cluster = state.shared.clusterAddressCache.get(address)
        if (cluster) {
          const { id: clusterId, topAttribution: attribution } = cluster
          if (clusterId in counterpartiesAgg) {
            counterpartiesAgg[clusterId].value = addBigNum(counterpartiesAgg[clusterId].value, value)
          } else {
            counterpartiesAgg[clusterId] = {
              type: 'cluster',
              attribution: attribution ? attribution : undefined,
              value
            }
          }
        } else {
          if (address in counterpartiesAgg) {
            counterpartiesAgg[address].value = addBigNum(counterpartiesAgg[address].value, value)
          } else {
            counterpartiesAgg[address] = {
              type: 'address',
              value
            }
          }
        }
      }
      txnCounterpartiesMap[`${id}${isOutput}`] = Object.entries(counterpartiesAgg).map((entry) => {
        const [entity, data] = entry
        const { type, attribution, value } = data
        return {
          entity,
          type,
          attribution,
          value
        }
      })
    }
    
    commit('SET_TXN_COUNTERPARTIES_MAP', { map: txnCounterpartiesMap })
  },
  // receipts
  async getReceipts({ commit }: { commit: Commit }, { network, id }: ItemRequest) {
    if (!state.receiptsCache.has(id as string)) {
      const response = await api.getReceipts({ network, id })
      if (response != null) {
        track('getReceipts', { network, id })
        commit('SET_RECEIPTS', { receipts: response })
        commit('SET_RECEIPTS_CACHE', { id, receipt: response[0] })
      }
    }
    if (network === 'tron' && !state.transactionsCache.has(id as string)) {
      const response = await api.getTransaction({ network, id })
      if (response != null) {
        track('getTransaction', { network, id })
        commit('SET_TRANSACTIONS_CACHE', { id, network, txn: response })
      }
    }
  },
  async getReceiptsBulk({ commit }: { commit: Commit }, { network, ids }: ItemsRequest) {
    const uncached = filter(ids, id => !state.receiptsCache.has(id as string)) as string[]
    if (uncached.length > 0) {
      const response = await api.getReceiptsBulk({ network, ids: uncached })
      if (response != null && response.length > 0) {
        track('getReceiptsBulk', { network, ids })
        for (const receipt of response) {
          const id = isFormattedLogEvent(receipt) ? receipt.transactionHash : receipt.id
          commit('SET_RECEIPTS_CACHE', { id, receipt: response[0] })
        }
      }
    }

    if (network === 'tron') {
      const uncachedTron = filter(ids, id => !state.transactionsCache.has(id as string)) as string[]
      const responses = await Promise.all(uncachedTron.map(id => api.getTransaction({ network, id })))
      for (const response of responses) {
        if (response != null) {
          const { txID: id } = response as Transaction
          track('getTransaction', { network, id })
          commit('SET_TRANSACTIONS_CACHE', { id, network, txn: response })
        }
      }
    }
  },
  // find input
  async findInput({ commit }: { commit: Commit }, { network, previousOutput, vout }: FindInputRequest) {
    // commit('SET_FOUND_INPUT', { input: undefined })
    const exists = state.foundInputs.has(`${previousOutput}_${vout}`)
    if (!exists) {
      const response = await api.findInput({ network, previousOutput, vout })
      if (response != null) {
        commit('SET_FOUND_INPUT', { ref: { txid: previousOutput, n: vout }, input: response })
        // also get txn heuristics and header for next hop
        // dispatch('cacheAncestorHeuristics', { network, triplets: [{
        //   id: response.txid,
        //   isOutput: false,
        //   index: response.index
        // }] })
        dispatch('cacheTxnHeaders', { network, txids: [response.txid] })
      } else {
        commit('ADD_UNSPENT_OUTPUT', { ref: { txid: previousOutput, n: vout } })
      }
    }
  },
  async resetInput({ commit }: { commit: Commit }) {
    // commit('SET_FOUND_INPUT', { input: undefined })
  },
  // for enriching input/output cars in txn views
  async cacheAncestorHeuristics(
    { commit }: { commit: Commit },
    { network, triplets }: { network: string; triplets: TxnTriplet[] }
  ) {
    const response: ExtendedTx[] | undefined = await api.getHeuristicsList({ network, list: triplets })
    if (response != null) {
      commit('SET_HEURISTICS_CACHE', { txns: response })
    }
  },
  async cacheTxnHeaders({ commit }: { commit: Commit }, { network, txids }: { network: string; txids: string[] }) {
    const response: ExtendedTx[] | undefined = await api.getTransactionsList({ network, list: txids, headerOnly: true })
    if (response != null) {
      commit('SET_TXN_HEADERS_CACHE', { txns: response })
    }
  },
  // external & nfts
  setTokenMetadata({ commit }: { commit: Commit }, { metadata }: { metadata: NftMetadata }) {
    commit('SET_TOKEN_METADATA', { metadata })
  },
  async getTokenUri({ commit }: { commit: Commit }, { url }: { url: string }) {
    try {
      const response = await api.getExternal(url)
      if (response != null) {
        const metadata = formatNftImageUrl(state.ipfsGateway, response)
        commit('SET_TOKEN_METADATA', { metadata })
      }
    } catch (e) {
      console.log('could not get external metadata')
      console.log(e)
      console.log(url)
      if (!url.endsWith('/')) {
        url = `${url}/`
        // this.dispatch('getTokenUri', { url })
        this.getTokenUri({ commit }, { url })
      }
    }
  },
  // stats
  async getSupportedStats({ commit }: { commit: Commit }, { network }: NetworkRequest) {
    const response = await api.getSupportedStats(network)
    if (response != null) {
      const assets: NetworkSupportedAsset[] = []
      if (response.supported && response.assets != null) {
        for (const a in response.assets) {
          assets.push({ ...{ network }, ...response.assets[a] })
        }
      }
      commit('SET_STATS_SUPPORTED_NETWORKS', { assets })
    }
  },
  // attributions
  async getChainAttributions({ commit }: { commit: Commit }, { addresses }: { addresses: string[] }) {
    commit('SET_CHAIN_ATTRIBUTIONS', {})
    const response = await api.getAttributions(addresses)
    if (response != null) {
      const addresses = Object.keys(response)
      if (addresses.length > 0) {
        commit('SET_CHAIN_ATTRIBUTIONS', response)
      }
    }
  }
})
