
import { Component, Prop, ProvideReactive, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { 
  Map as MapBox,
  MapboxOptions,
  NavigationControl,
  Popup,
  AnySourceData,
  AnyLayer,
  StyleFunction,
  Expression,
  MapLayerEventType,
  EventData,
  SymbolPaint
} from 'mapbox-gl'
import ColorPicker from '@/subcomponents/ColorPicker.vue'
import { Node, StringArrayMap } from '@/utils/api'
import { titleCase, md5 } from '@/utils/general'
import { ElectrumPeer, ElectrumUser, ElectrumWalletSingleIP, ElectrumWalletSummary, NetworkItemConfig, NetworkItemsConfig } from '@/types/bitcoin'
import { ElectrumWalletSummaries } from '@/store/p2p'

export interface NetworksConfig {
  display: NetworksDisplayConfig
  icons: IconsConfig
  layers: MapLayers
}

export interface NetworkDisplayConfig {
  nodes: NetworkItemConfig
  electrumServers: NetworkItemConfig
  electrumUsers: NetworkItemConfig
  electrumTxns: NetworkItemConfig
  electrumWallets: NetworkItemConfig
  electrumWatchlists: NetworkItemConfig
}

export interface NetworksDisplayConfig {
  [key: string]: NetworkDisplayConfig
}

type NetworkMarkerTypes = 'nodes' | 'electrumServers' | 'electrumUsers' | 'electrumUserTxns' | 'electrumWallets' | 'electrumWatchlists'
type NetworkDataTypes = 'nodesData' | 'electrumServersData' | 'electrumUsersData' | 'electrumUserTxnsData' | 'electrumWalletsData' | 'electrumWatchlistsData'

interface IconFile {
  path: string
  name: string
}

interface IconProps {
  name: string
  color: string
  size: number | StyleFunction | Expression
  offset?: number[] | Expression
  halo?: boolean
}

interface IconsConfig {
  [key: string]: {
    nodes: IconFile,
    electrum: IconFile
  }
}

interface MapLayers {
  [key: string]: {
    nodes: {
      icons: IconProps
    },
    electrumServers: {
      icons: IconProps
    },
    electrumUsers: {
      icons: IconProps
    },
    electrumUserTxns: {
      icons: IconProps
    },
    electrumWallets: {
      icons: IconProps
    },
    electrumWatchlists: {
      icons: IconProps
    }
  }
}

interface NetworkLayerToggle {
  nodes: boolean
  nodesData: boolean
  electrumServers: boolean
  electrumServersData: boolean
  electrumUsers: boolean
  electrumUsersData: boolean
  electrumUserTxns: boolean
  electrumUserTxnsData: boolean
  electrumWallets: boolean
  electrumWalletsData: boolean
  electrumWatchlists: boolean
  electrumWatchlistsData: boolean
}

interface NetworkLayerToggles {
  [key: string]: NetworkLayerToggle
}

interface Label {
  nodes: string
  servers: string
  users: string
  txns: string
  wallets: string
  watchlists: string
}

interface Labels {
  [key: string]: Label
}

function getDataType(type: NetworkMarkerTypes): NetworkDataTypes {
  switch (type) {
    case 'nodes':
      return 'nodesData'
    case 'electrumServers':
      return 'electrumServersData'
    case 'electrumUsers':
      return 'electrumUsersData'
    case 'electrumUserTxns':
      return 'electrumUserTxnsData'
    case 'electrumWallets':
      return 'electrumWalletsData'
    case 'electrumWatchlists':
      return 'electrumWatchlistsData'
  }
}

function getLayerName(network: string, type: NetworkMarkerTypes): string {
  return `${network}-${type}`
}

@Component({
  components: {
    ColorPicker
  },
  computed: {
    ...mapState([
      'mapBoxToken',
      'allP2PNodes',
      'electrumPeersMap',
      'electrumUsersMap',
      'electrumTransactionsMap',
      'electrumUsersWithTransactionsMap',
      'electrumWalletSummariesFiltered',
      'electrumWatchlistSummaries',
      'walletsLastUpdated',
      'filteredWalletsLastUpdated'
    ])
  }
})
export default class P2PMap extends Vue {
  @Prop() networksConfig!: NetworksConfig
  public titleCase = titleCase
  public mapBoxToken!: string
  public allP2PNodes!: Array<Node>
  public electrumPeersMap!: Map<string, ElectrumPeer[]>
  public electrumUsersMap!: Map<string, Map<string, ElectrumUser>>
  public electrumTransactionsMap!: Map<string, StringArrayMap>
  public electrumUsersWithTransactionsMap!: Map<string, ElectrumUser[]>
  public electrumWalletSummariesFiltered!: ElectrumWalletSummaries
  public electrumWatchlistSummaries!: ElectrumWalletSummaries
  public walletsLastUpdated!: number
  public filteredWalletsLastUpdated!: string
  public show: boolean = false
  public loading: boolean = false
  public walletsInitialized: { [key: string]: boolean } = {}
  public showOptions: boolean = false
  private map!: MapBox
  private mapOptions = {
    accessToken: '',
    style: "mapbox://styles/mapbox/light-v11",
    center: { lng: 0, lat: 0 },
    minZoom: 1.35,
    zoom: 1.95,
    projection: {
      name: 'equirectangular'
    }
  }
  private mapElement!: HTMLElement
  private popup?: Popup

  @ProvideReactive() public networkLayerToggles: NetworkLayerToggles | null = null
  public popupKeepOpen: boolean = false
  public nodeInfo: Node | null = null
  public electrumServerInfo: ElectrumPeer | null = null
  public electrumUserInfo: ElectrumUser | null = null
  public electrumUserTxnsInfo: ElectrumUser | null = null
  public electrumWalletInfo: ElectrumWalletSingleIP | null = null
  public electrumWatchlistInfo: ElectrumWalletSingleIP | null = null
  public labels: Labels | null = null
  private layerOrder: string[] = []
  private layerOrdering: string[] = ['nodes', 'electrumServers', 'electrumUsers', 'electrumWatchlists', 'electrumUserTxns', 'electrumWallets']

  public networkRowIds: { [key: string]: string } = {
    bitcoin: '',
    litecoin: ''
  }

  get iconFiles(): IconsConfig {
    return this.networksConfig.icons
  }

  get layerProps(): MapLayers {
    return this.networksConfig.layers
  }

  get networks(): NetworkItemsConfig[] {
    return Object.keys(this.networksConfig.display).map(network => ({
      name: network,
      ...this.networksConfig.display[network]
    }))
  }

  // instantiation start

  created() {
    this.networkLayerToggles = this.networks.reduce((merged, network) => {
      return {
        ...merged,
        [`${network.name}`]: {
          nodes: false,
          nodesData: false,
          electrumServers: false,
          electrumServersData: false,
          electrumUsers: false,
          electrumUsersData: false,
          electrumUserTxns: false,
          electrumUserTxnsData: false,
          electrumWallets: false,
          electrumWalletsData: false,
          electrumWatchlists: false,
          electrumWatchlistsData: false,
        }
      }
    }, <NetworkLayerToggles>{})
  }

  async mounted() {
    this.mapElement = this.$refs.p2pMap as HTMLElement
    this.mapOptions.accessToken = this.mapBoxToken
    const options = { container: this.$refs.p2pMap as HTMLElement, ...this.mapOptions } as MapboxOptions
    this.map = new MapBox(options)
    this.map.addControl(new NavigationControl())
    this.map.on('load', this.initMap)
    this.show = true
  }

  async initMap() {
    this.loading = true
    this.toggleInteractivity()
    await this.loadIcons()
    for (const network of this.networks) {
      const { name, nodes, electrumServers, electrumUsers, electrumTxns, electrumWallets, electrumWatchlists } = network
      if (nodes.enabled) {
        if (nodes.preload || nodes.display) {
          await this.getNodesData(network.name)
          if (nodes.display) {
            this.addMarkers(name, 'nodes')
          }
        }
      }

      if (electrumServers.enabled) {
        if (electrumServers.preload || electrumServers.display) {
          await this.getElectrumServersData(network.name)
          if (electrumServers.display) {
            this.addMarkers(name, 'electrumServers')
          }
        }
      }

      if (electrumUsers.enabled) {
        if (electrumUsers.preload || electrumUsers.display) {
          await this.getElectrumUsersData(network.name)
          if (electrumUsers.display) {
            this.addMarkers(name, 'electrumUsers')
          }
        }
      }

      if (electrumTxns.enabled) {
        if (electrumTxns.preload || electrumTxns.display) {
          await this.getElectrumUsersTxnsData(network.name)
          if (electrumTxns.display) {
            this.addMarkers(name, 'electrumUserTxns')
          }
        }
      }

      if (electrumWallets.enabled) {
        if (electrumWallets.preload || electrumWallets.display) {
          await this.getElectrumWalletsData(network.name)
          if (electrumWallets.display) {
            this.addMarkers(name, 'electrumWallets')
          }
        }
      }

      if (electrumWatchlists.enabled) {
        if (electrumWatchlists.preload || electrumWatchlists.display) {
          await this.getElectrumWatchlistsData(network.name)
          if (electrumWatchlists.display) {
            this.addMarkers(name, 'electrumWatchlists')
          }
        }
      }
    }
    this.loading = false
    // this.toggleInteractivity() // uncomment if disable interactivity while loading
    this.$store.dispatch('updateSnackbar', { show: true, text: `Data loaded. Map interactivity is enabled.` })

    this.popup = new Popup({
      offset: [0, 0],
      closeButton: false,
      closeOnClick: false
    })
  }

  // instantiation end
  // data start

  public rowIDHash(layer: NetworkLayerToggle, index: number, label: Label): string {
    return md5(JSON.stringify({layer, index, label}))
  }

  private nodes(): Array<Node> {
    if (this.allP2PNodes == null) {
      return []
    }
    return this.allP2PNodes
  }

  private electrumPeers(network: string): Array<ElectrumPeer> {
    if (this.electrumPeersMap == null) {
      return []
    }
    const peers = this.electrumPeersMap.get(network)
    return peers == null ? [] : peers
  }

  electrumUsers(network: string): Array<ElectrumUser> {
    if (this.electrumUsersMap == null) {
      return []
    }
    const users = this.electrumUsersMap.get(network)
    return users == null ? [] : Array.from(users.values())
  }

  electrumTransactions(network: string): StringArrayMap {
    if (this.electrumTransactionsMap == null) {
      return {}
    }
    const txns = this.electrumTransactionsMap.get(network)
    if (txns == null) {
      return {}
    }
    return txns
  }

  electrumUsersWithTransactions(network: string): Array<ElectrumUser> {
    if (this.electrumUsersWithTransactions == null) {
      return []
    }
    const txns = this.electrumUsersWithTransactionsMap.get(network)
    return txns == null ? [] : txns
  }

  electrumUserTxns(network: string, ip: string): number {
    const txns = this.electrumTransactions(network)
    if (txns[ip] == null) {
      return 0
    }
    return txns[ip].length
  }

  unwindWalletIPs(wallets: Array<ElectrumWalletSummary>): Array<ElectrumWalletSingleIP> {
    return wallets.flatMap(({ ips, ...wallet }) => ips.map(ip => ({...wallet, ...ip })))
  }

  electrumWallets(network: string): Array<ElectrumWalletSingleIP> {
    if (this.electrumWalletSummariesFiltered[network] == null) {
      return []
    }
    const wallets = this.electrumWalletSummariesFiltered[network].values()
    return this.unwindWalletIPs(wallets)
  }

  electrumWatchlists(network: string): Array<ElectrumWalletSingleIP> {
    if (this.electrumWatchlistSummaries[network] == null) {
      return []
    }
    const watchlists = this.electrumWatchlistSummaries[network].values()
    return this.unwindWalletIPs(watchlists)
  }

  getCount(network: string, type: string): number {
    let count = 0
    switch (type) {
      case 'nodes':
        count = network === 'bitcoin' ? this.nodes().length : 0
        break
      case 'servers':
        count = this.electrumPeers(network).length
        break
      case 'users':
        count = this.electrumUsers(network).length
        break
      case 'txns':
        count = this.electrumUsersWithTransactions(network).length
        break
      case 'wallets':
        count = this.electrumWallets(network).length
        break
      case 'watchlists':
        count = this.electrumWatchlists(network).length
        break
    }
    if (this.networkLayerToggles != null && this.labels != null) {
       this.networkRowIds[network] = this.rowIDHash(this.networkLayerToggles[network], count, this.labels[network])
    }
    return count
  }

  private async getNodesData(network: string) {
    this.$store.dispatch('updateSnackbar', { show: true, text: `Loading nodes...` })
    await this.$store.dispatch('getAllNodes', { network })
    this.networkLayerToggles![network].nodesData = true
    this.updateLabels()
  }

  private async getElectrumServersData(network: string) {
    this.$store.dispatch('updateSnackbar', { show: true, text: `Loading Electrum Servers...` })
    await this.$store.dispatch('getElectrumPeers', { network })
    this.networkLayerToggles![network].electrumServersData = true
    this.updateLabels()
  }

  private async getElectrumUsersData(network: string) {
    this.$store.dispatch('updateSnackbar', { show: true, text: `Loading Electrum Users...` })
    await this.$store.dispatch('getElectrumUsers', { network })
    this.networkLayerToggles![network].electrumUsersData = true
    this.updateLabels()
  }

  private async getElectrumUsersTxnsData(network: string) {
    this.$store.dispatch('updateSnackbar', { show: true, text: `Loading Electrum Transactions...` })
    await this.$store.dispatch('getElectrumTransactions', { network })
    this.$store.dispatch('updateSnackbar', { show: true, text: 'Loading Electrum users with transactions' })
    await this.$store.dispatch('getElectrumUsersWithTransactions', { network })
    this.networkLayerToggles![network].electrumUserTxnsData = true
    this.updateLabels()
  }

  private async getElectrumWalletsData(network: string) {
    this.$store.dispatch('updateSnackbar', { show: true, text: `Loading Electrum Wallets...`, timeout: 5000 })
    await this.$store.dispatch('getElectrumWallets', { network })
    this.networkLayerToggles![network].electrumWalletsData = true
    this.updateLabels()
  }

  private async getElectrumWatchlistsData(network: string) {
    this.$store.dispatch('updateSnackbar', { show: true, text: `Loading Electrum Watchlists...` })
    await this.$store.dispatch('getElectrumWatchlists', { network })
    this.networkLayerToggles![network].electrumWatchlistsData = true
    this.updateLabels()
  }

  private getMapDataSource(data: any[]): AnySourceData {
    const features = data.map(p => ({
      type: 'Feature',
      properties: p,
      geometry: {
        type: 'Point',
        coordinates: [p.longitude ?? p.logitude, p.latitude] // node data has a typo in the schema :(
      }
    })) as Array<GeoJSON.Feature<GeoJSON.Geometry>>
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features
      }
    }
  }

  // data end
  // Icon markers start

  private async loadIcon(path: string, name: string) {
    return new Promise((resolve, reject) => {
      this.map.loadImage(path, (error, image) => {
        if (error) {
          return reject(error)
        }
        this.map.addImage(name, image!, { sdf: true })
        resolve(null)
      })
    })
  }

  private async loadIcons() {
    const networks = Object.keys(this.iconFiles)
    for (const network of networks) {
      const { nodes, electrum } = this.iconFiles[network]
      await this.loadIcon(nodes.path, nodes.name)
      await this.loadIcon(electrum.path, electrum.name)
    }
  }

  public addMarkers(network: string, type: NetworkMarkerTypes) {
    const name = getLayerName(network, type)
    const data = this.getMarkerData(network, type)
    const { icons } = this.layerProps[network][type]
    const source = this.getMapDataSource(data)
    this.map.addSource(name, source)

    const iconLayer = this.getIconLayer(name, icons)
    const nextLayer = this.getNextLayer(network, name)
    this.map.addLayer(iconLayer, nextLayer)
    this.addLayerToOrder(name, nextLayer)
    this.networkLayerToggles![network][type] = true
    this.addPopupListeners(network, name, type)

    if (type === 'electrumWallets') {
      this.walletsInitialized[network] = true
    }
  }

  public removeMarkers(network: string, type: NetworkMarkerTypes) {
    const name = getLayerName(network, type)
    this.removePopupListeners(network, name, type)
    this.map.removeLayer(name)
    this.map.removeSource(name)
  }

  // Icon markers end
  // Popups start

  addPopupListeners(network: string, layer: string, popupType: NetworkMarkerTypes) {
    this.map.on('mouseenter', layer, (e) => this.getPopup(e, network, popupType))
    this.map.on('mousedown', layer, this.keepPopupOpen)
    this.map.on('mouseleave', layer, this.closePopupOnMouseOut)
  }

  removePopupListeners(network: string, layer: string, popupType: NetworkMarkerTypes) {
    this.map.off('mouseenter', layer, (e) => this.getPopup(e, network, popupType))
    this.map.off('mousedown', layer, this.keepPopupOpen)
    this.map.off('mouseleave', layer, this.closePopupOnMouseOut)
  }

  resetPopups() {
    this.nodeInfo = null
    this.electrumServerInfo = null
    this.electrumUserInfo = null
    this.electrumUserTxnsInfo = null
    this.electrumWalletInfo = null
    this.electrumWatchlistInfo = null
  }

  getPopup(e: MapLayerEventType['mouseenter'] & EventData, network: string, type: NetworkMarkerTypes) {
    // Change the cursor style as a UI indicator.
    this.map.getCanvas().style.cursor = 'pointer'
    if (e.features != null) {
      const properties = e.features[0].properties
      if (properties != null) {
        if (properties.versions != null) {
          properties.versions = JSON.parse(properties.versions)
        }
        if (type === 'nodes') {
          this.resetPopups()
          this.nodeInfo = properties as Node
        } else if (type === 'electrumServers') {
          this.resetPopups()
          this.electrumServerInfo = properties as ElectrumPeer
        } else if (type === 'electrumUsers') {
          this.resetPopups()
          this.electrumUserInfo = properties as ElectrumUser
        } else if (type === 'electrumUserTxns') {
          this.resetPopups()
          this.electrumUserTxnsInfo = properties as ElectrumUser
        } else if (type === 'electrumWallets') {
          this.resetPopups()
          this.electrumWalletInfo = properties as ElectrumWalletSingleIP
        } else if (type === 'electrumWatchlists') {
          this.resetPopups()
          this.electrumWatchlistInfo = properties as ElectrumWalletSingleIP
        }
      }
    }
    if (this.popup != null) {
      this.popup.setLngLat(e.lngLat).setDOMContent(this.$refs.popup as HTMLElement).addTo(this.map)
    }
  }

  public formatIPLink(network: string, ip: string) {
    return `explorer/ip/${network}/${ip}`
  }

  public formatWalletLink(network: string, id: string) {
    return `explorer/wallet/${network}/${id}`
  }

  private keepPopupOpen(e: any) {
    this.popupKeepOpen = true
  }

  private resetPopupInfos() {
    this.nodeInfo = null
    this.electrumServerInfo = null
    this.electrumUserInfo = null
    this.electrumUserTxnsInfo = null
  }

  private closePopupOnMouseOut(e: any) {
    this.map.getCanvas().style.cursor = ''
    if (!this.popupKeepOpen && this.popup != null) {
      this.popup.remove()
      this.resetPopupInfos()
    }
  }

  public closePopup() {
    this.popupKeepOpen = false
    if (this.popup != null) {
      this.popup.remove()
    }
    this.resetPopupInfos()
  }

  // Popups end
  // Layers start

  public getIconLayer(source: string, iconProps: IconProps): AnyLayer {
    const paint: SymbolPaint = {
      'icon-color': iconProps.color
    }
    if (iconProps.halo) {
      paint['icon-halo-color'] = 'rgba(237,14,129,0.4)'
      paint['icon-halo-width'] = 0.5
      // paint['icon-halo-blur'] = 5
    }
    return {
      id: source,
      type: 'symbol',
      source,
      layout: {
        'icon-image': iconProps.name,
        'icon-size': iconProps.size,
        'icon-allow-overlap': true,
        'text-allow-overlap': true,
        // 'icon-offset': iconProps.offset
      },
      paint
    }
  }

  private addLayerToOrder(layer: string, nextLayer: string | undefined) {
    if (nextLayer != null && this.layerOrder.indexOf(layer) === -1) {
      const nextLayerIndex = this.layerOrder.indexOf(nextLayer)
      this.layerOrder.splice(nextLayerIndex, 1, layer, nextLayer)
    } else {
      this.layerOrder.push(layer)
    }
  }

  private getNextLayer(network: string, layerName: string): string | undefined {
    const type = layerName.replace(`${network}-`, '')
    const ordering = Array.from(this.layerOrdering)
    const ourTypeIndex = ordering.indexOf(type)
    if (ordering.length > ourTypeIndex + 1) {
      const nextLayers = ordering.slice(ourTypeIndex + 1)
      if (nextLayers.length > 0) {
        for (const nextType of nextLayers) {
          for (const layer of this.layerOrder) {
            const layerType = layer.replace(`${network}-`, '')
            if (nextType === layerType) {
              return layer
            }
          }
        }
      }
    }
    return undefined
  }

  // Layers end
  // Icon colors start

  public async setNodeColor(network: string, color: string) {
    this.layerProps[network].nodes.icons.color = color
    this.removeMarkers(network, 'nodes')
    this.addMarkers(network, 'nodes')
  }

  public async setElectrumServerColor(network: string, color: string) {
    this.layerProps[network].electrumServers.icons.color = color
    this.removeMarkers(network, 'electrumServers')
    this.addMarkers(network, 'electrumServers')
  }

  public async setElectrumUserColor(network: string, color: string) {
    this.layerProps[network].electrumUsers.icons.color = color
    this.removeMarkers(network, 'electrumUsers')
    this.addMarkers(network, 'electrumUsers')
  }

  public async setElectrumUserTxnColor(network: string, color: string) {
    this.layerProps[network].electrumUserTxns.icons.color = color
    this.removeMarkers(network, 'electrumUserTxns')
    this.addMarkers(network, 'electrumUserTxns')
  }

  public async setElectrumWalletColor(network: string, color: string) {
    this.layerProps[network].electrumWallets.icons.color = color
    this.removeMarkers(network, 'electrumWallets')
    this.addMarkers(network, 'electrumWallets')
  }

  public async setElectrumWatchlistColor(network: string, color: string) {
    this.layerProps[network].electrumWatchlists.icons.color = color
    this.removeMarkers(network, 'electrumWatchlists')
    this.addMarkers(network, 'electrumWatchlists')
  }

  // Icon colors end
  // Interactivity start

  fetchData(network: string, type: NetworkMarkerTypes) {
    switch (type) {
      case 'nodes':
        return this.getNodesData(network)
      case 'electrumServers':
        return this.getElectrumServersData(network)
      case 'electrumUsers':
        return this.getElectrumUsersData(network)
      case 'electrumUserTxns':
        return this.getElectrumUsersTxnsData(network)
      case 'electrumWallets':
        return this.getElectrumWalletsData(network)
      case 'electrumWatchlists':
        return this.getElectrumWatchlistsData(network)
    }
  }

  getMarkerData(network: string, type: NetworkMarkerTypes) {
    switch (type) {
      case 'nodes':
        return this.nodes()
      case 'electrumServers':
        return this.electrumPeers(network)
      case 'electrumUsers':
        return this.electrumUsers(network)
      case 'electrumUserTxns':
        return this.electrumUsersWithTransactions(network)
      case 'electrumWallets':
        return this.electrumWallets(network)
      case 'electrumWatchlists':
        return this.electrumWatchlists(network)
    }
  }

  async toggleMarkers(network: string, type: NetworkMarkerTypes) {
    const dataType = getDataType(type)
    if (this.networkLayerToggles != null) {
      if (!this.networkLayerToggles[network][dataType]) {
        await this.fetchData(network, type)
        this.addMarkers(network, type)
      } else {
        const visible = this.networkLayerToggles[network][type]
        const name = getLayerName(network, type)
        this.map.setLayoutProperty(name, 'visibility', visible ? 'visible' : 'none')
      }
    }
  }

  toggleInteractivity() {
    if (this.loading) {
      // don't disable interactivity while loading
      // this.map.scrollZoom.disable()
      // this.map.boxZoom.disable()
      // this.map.dragPan.disable()
      // this.map.keyboard.disable()
      // this.map.doubleClickZoom.disable()

      // keep disabled
      this.map.dragRotate.disable()
      this.map.touchZoomRotate.disable()
      this.map.touchPitch.disable()
    } else {
      this.map.scrollZoom.enable()
      this.map.boxZoom.enable()
      this.map.dragPan.enable()
      this.map.keyboard.enable()
      this.map.doubleClickZoom.enable()
    }
  }

  // Interactivity end
  // helpers start

  public getCity(city: string | null | undefined): string {
    if (city == null || city === '') {
      return 'Unknown'
    }
    return city
  }

  updateLabels() {
    if (this.labels == null) {
      this.labels = {}
    }
    for (const { name } of this.networks) {
      const nodesCount = this.getCount(name, 'nodes')
      const serversCount = this.getCount(name, 'servers')
      const usersCount = this.getCount(name, 'users')
      const txnsCount = this.getCount(name, 'txns')
      const walletCount = this.getCount(name, 'wallets')
      const watchlistCount = this.getCount(name, 'watchlists')
      this.labels[name] = {
        nodes: `Nodes (${nodesCount > 0 ? nodesCount : '-'})`,
        servers: `Electrum Servers (${serversCount > 0 ? serversCount : '-'})`,
        users: `Electrum Users (${usersCount > 0 ? usersCount : '-'})`,
        txns: `Electrum Users Txns (${txnsCount > 0 ? txnsCount : '-'})`,
        wallets: `Electrum Wallets (${walletCount > 0 ? walletCount : '-'})`,
        watchlists: `Electrum Watchlists (${watchlistCount > 0 ? watchlistCount : '-'})`
      }
    }
  }

  @Watch('filteredWalletsLastUpdated')
  updateWallets() {
    if (this.filteredWalletsLastUpdated.includes('_')) {
      const network = this.filteredWalletsLastUpdated.split('_')[0]
      if (this.walletsInitialized[network]) {
        this.removeMarkers(network, 'electrumWallets')
        this.addMarkers(network, 'electrumWallets')
        const walletCount = this.getCount(network, 'wallets')
        if (this.labels != null) {
          this.labels[network].wallets = `Electrum Wallets (${walletCount > 0 ? walletCount : '-'})`
        }
      }
    }
  }
}
