import FingerprintJS, { Agent } from '@fingerprintjs/fingerprintjs'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { md5, objectToHeaderFields } from './general'
import { UserActivity } from './api'

interface AnyMap {
  [key: string]: any
}

export class MachineID {
  private fingerprint!: Agent
  private mIds: Set<string> = new Set()
  private hlIds: Set<string> = new Set()
  private componentSources: Array<string> = [
    'fonts',
    'fontPreferences',
    'languages',
    'hardwareConcurrency',
    'timezone',
    'sessionStorage',
    'localStorage',
    'indexedDB',
    'openDatabase',
    'platform',
    'plugins',
    'touchSupport',
    'vendor',
    'vendorFlavors',
    'cookiesEnabled',
    'monochrome',
    'videoCard',
    'pdfViewerEnabled',
    'architecture'
  ]

  constructor() {}

  async init() {
    this.fingerprint = await FingerprintJS.load()
  }

  async getFingerprints() {
    const fingerprint = await this.fingerprint.get()
    this.mIds.add(fingerprint.visitorId)
    const data = fingerprint.components
    const values = this.componentSources.map((key) => {
      return (data as AnyMap)[key].value
    })
    const stringValue = values.map((v) => JSON.stringify(v)).join('|')
    const hash = md5(stringValue)
    this.hlIds.add(hash)
    return { mid: fingerprint.visitorId, hlid: hash }
  }

  async allIds(): Promise<{ mids: string[]; hlids: string[] }> {
    await this.getFingerprints()
    return {
      mids: Array.from(this.mIds),
      hlids: Array.from(this.hlIds)
    }
  }
}

export const machineId = new MachineID()

export const analytics = AnalyticsBrowser.load({ writeKey: 'HcU0U9AjjdYCp5D7CRP9as7a3kOimFRZ' })

export async function identify(userId: string, traits: { [key: string]: any } = {}) {
  const fingerprints = await machineId.getFingerprints()
  analytics.identify(userId, {
    ...fingerprints,
    ...traits
  })
}

export function track(event: string, properties?: { [key: string]: any }) {
  // analytics.track(event, { properties })
}

export function activityToHeader(activity: UserActivity, headerFields: string[] = []): string {
  for (const [key, value] of Object.entries(activity)) {
    if (key === 'timeStarted') {
      headerFields.push(`${key}=${(value as Date).getTime()}`)
    } else if (key !== 'data') {
      headerFields.push(`${key}=${value}`)
    }
  }
  switch (activity.action) {
    case 'graph-flow': // this is the only one with problematic dupe keys
      const { network, source, destination } = activity.data
      headerFields.push(`network=${network}`)
      const { id: sourceId, isOutput: sourceIsOutput, index: sourceIndex } = source
      headerFields.push(`sourceId=${sourceId}`)
      headerFields.push(`sourceIsOutput=${sourceIsOutput}`)
      headerFields.push(`sourceIndex=${sourceIndex}`)
      const { id: destinationId, isOutput: destinationIsOutput, index: destinationIndex } = destination
      headerFields.push(`destinationId=${destinationId}`)
      headerFields.push(`destinationIsOutput=${destinationIsOutput}`)
      headerFields.push(`destinationIndex=${destinationIndex}`)
      break
    default:
      headerFields = objectToHeaderFields(activity.data, headerFields)
  }
  
  // get rid of dupes and return in one string
  return Array.from(new Set(headerFields)).join(',')
}
