
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ConfirmationForm extends Vue {
  @Prop() actionMessageEnd!: () => string
  @Prop() hideConfirmationAction!: () => void

  public hideConfirmation: boolean = false

  public doAction() {
    if (this.hideConfirmation) {
      if (this.hideConfirmationAction != null) {
        this.hideConfirmationAction()
      }
    }
  }
}
