
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { ElectrumWallets } from '@/store/p2p'
import ServerTable from '@/subcomponents/ServerTable.vue'
import MapCard from '@/subcomponents/MapCard.vue'
import { Header } from '@/subcomponents/types/genericTable'
import { ASN, Carrier, IPMetadata, Threat } from '@/types/ip'
import { titleCase } from '@/utils/general'

@Component({
  components: {
    ServerTable,
    MapCard
  },
  computed: mapState(['electrumWallets', 'electrumWalletUpdated'])
})
export default class Wallet extends Vue {
  public loading: boolean = false
  public id: string = ''
  public network: string = ''
  public networkDisplayName: string = ''
  public electrumWallets!: ElectrumWallets
  public electrumWalletUpdated!: number
  public show: boolean = false

  public addressHeaders: Header[] = [
    { text: 'Address', value: 'address', copyButton: true, link: {
        label: (address: string) => address,
        url: (address: string) => `/explorer/address/${this.$route.params.network}/${address}`,
        target: '_blank',
        field: 'address'
      } }
  ]

  public IPHeaders: Header[] = [
    { text: 'IP', value: 'ip', copyButton: true, link: {
        label: (ip: string) => ip,
        url: (ip: string) => `/explorer/ip/${this.$route.params.network}/${ip}`,
        target: '_blank',
        field: 'ip'
      }, width: '160px' },
    { text: 'Country', value: ['emoji_flag', 'country_name'], width: '110px' },
    { text: 'Region', value: 'region' },
    { text: 'City', value: 'city' },
    { text: 'ISP/ASN', value: 'asn', transform: ({name, asn}: ASN) => name != null ? name : asn },
    { text: 'Mobile Carrier', value: 'carrier', transform: (carrier: Carrier) => carrier != null ? carrier.name : '' },
    { text: 'Threat', value: 'threat', transform: (t: Threat) => !!t.is_threat },
    { text: 'Tor', value: 'threat', transform: (t: Threat) => !!t.is_tor },
    { text: 'Datacenter', value: 'threat', transform: (t: Threat) => !!t.is_datacenter },
    { text: 'Proxy', value: 'threat', transform: (t: Threat) => !!t.is_proxy },
    { text: 'Last Seen', value: 'lastSeen', isTimestamp: true }
  ]

  get coords(): { lat: number, lng: number }[] {
    const w = this.electrumWallets[this.network].get(this.id)
    if (w != null) {
      return w.wallet.ips.map(ip => ({lat: ip.latitude ?? 0, lng: ip.longitude ?? 0}))
    }
    return []
  }

  get addresses(): { address: string }[] {
    const w = this.electrumWallets[this.network].get(this.id)
    if (w != null) {
      return w.wallet.addresses.map(address => ({ address }))
    }
    return []
  }

  get ips(): IPMetadata[] {
    const w = this.electrumWallets[this.network].get(this.id)
    if (w != null) {
      return w.wallet.ips
    }
    return []
  }

  @Watch('electrumWalletUpdated')
  private walletUpdated() {
    this.show = true
    this.loading = false
  }
  
  async created() {
    this.id = this.$route.params.id
    this.network = this.$route.params.network
    this.networkDisplayName = titleCase(this.network)
  }

  async mounted() {
    this.loading = true
    const { network, id } = this
    await this.$store.dispatch('getElectrumWalletAddresses', { network, id })
    this.loading = false
  }
}
