
import { Investigation, InvestigationState } from '@/store/investigations/investigations'
import FileUpload from '@/subcomponents/FileUpload.vue'
import { CustomFile, cutMiddle, getCsvOrExcelData } from '@/utils/general'
import { filter } from '@/utils/filters'
import { DSVRowArray } from 'd3'
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { classifyNodeId } from '@/utils/viz'
import { WatchlistAddress, WatchlistAlert } from '@/utils/api'
import ServerTable from '@/subcomponents/ServerTable.vue'
import { Header } from '@/subcomponents/types/genericTable'
import Dialog, { DialogConfig } from '../Dialog.vue'
import ConfirmationForm from '@/subcomponents/ConfirmationForm.vue'

export interface DropdownItem {
  text: string
  icon: string
}

@Component({
  components: {
    FileUpload,
    ServerTable,
    Dialog
  },
  computed: mapState(['investigations', 'watchlist', 'investigationAlerts', 'investigationDeletionConfirmations'])
})
export default class CaseManagement extends Vue {
  public investigations!: Investigation[]
  public show: boolean = false

  public investigationDeletionConfirmations!: boolean
  public deleteDialog: DialogConfig<typeof ConfirmationForm> = {
    button: {
      icon: ''
    },
    dialog: {
      title: 'Confirm Delete',
      action: 'Delete',
      actionClick: this.deleteInvestigation
    },
    content: ConfirmationForm,
    props: {
      actionMessageEnd: () => ' you want to delete this investigation? This cannot be undone.',
      hideConfirmationAction: () => this.$store.dispatch('setInvestigationDeletionConfirmations', { on: false })
    },
    show: false
  }

  public showShare: boolean = false
  private targetInvestigation?: Investigation
  private targetIndex: number = -1
  public newName: string = ''
  public showErrorMessage: boolean = false
  public shareUsername: string = ''

  public watchlist!: WatchlistAddress[]
  public investigationAlerts!: WatchlistAlert[]
  public showAlertsDialog: boolean = false

  public toWatchlist = {
    address: '',
    network: 'bitcoin',
    cluster: false,
    error: ''
  }
  public watchlistHeaders: Header[] = [
    {
      text: 'Address',
      value: '',
      sortable: false,
      link: {
        label: (item: WatchlistAddress) => item.address,
        url: (item: WatchlistAddress) =>
          `/explorer/${item.cluster ? 'cluster' : 'address'}/${item.network}/${item.address}`,
        target: '_blank',
        field: 'address'
      },
      clipping: {
        enabled: true,
        function: cutMiddle,
        length: 6,
        tooltip: true
      },
      copyButton: true,
      width: '160px'
    },
    {
      text: 'Cluster',
      value: 'cluster',
      sortable: false
    },
    {
      text: 'Network',
      value: 'network',
      sortable: false
    },
    {
      text: 'Added',
      value: 'timeAdded',
      sortable: false,
      transform: (time: Date) => this.formatDateString(time.toString())
    },
    {
      text: 'Delete',
      value: '',
      button: {
        click: this.deleteWatchlistAddress,
        icon: 'mdi-delete-outline',
        color: 'red'
      }
    }
  ]

  public alertsPage: number = 1
  public alertsPerPage: number = 10
  public alertsPerPageOptions = [10, 20, 50]
  public alertsHeaders: Header[] = [
    {
      text: 'Address',
      value: '',
      sortable: false,
      link: {
        label: (item: WatchlistAlert) => item.address,
        url: (item: WatchlistAlert) =>
          `/explorer/${item.cluster ? 'cluster' : 'address'}/${item.network}/${item.address}`,
        target: '_blank',
        field: 'address'
      },
      clipping: {
        enabled: true,
        function: cutMiddle,
        length: 6,
        tooltip: true
      },
      copyButton: true,
      width: '160px'
    },
    {
      text: 'Cluster',
      value: 'cluster',
      sortable: false
    },
    {
      text: 'Network',
      value: 'network',
      sortable: false
    },
    {
      text: 'Transaction',
      value: '',
      sortable: false,
      link: {
        label: (item: WatchlistAlert) => item.transaction,
        url: (item: WatchlistAlert) => `/explorer/transaction/${item.network}/${item.transaction}`,
        target: '_blank',
        field: 'transaction'
      },
      clipping: {
        enabled: true,
        function: cutMiddle,
        length: 6,
        tooltip: true
      },
      copyButton: true,
      width: '150px'
    },
    {
      text: 'Direction',
      value: 'isOutput',
      sortable: false,
      transform: (isOutput: boolean) => isOutput ? 'incoming' : 'outgoing'
    },
    {
      text: 'Status',
      value: 'status',
      sortable: false
    },
    {
      text: 'Time',
      value: 'alertTime',
      sortable: false,
      transform: (time: Date) => this.formatDateString(time.toString())
    },
    {
      text: 'Delete',
      value: '',
      button: {
        click: this.deleteAlert,
        icon: 'mdi-delete-outline',
        color: 'red'
      }
    }
  ]

  private editingNameIndex: number = -1
  private editingName: string = ''

  public addItems: DropdownItem[] = [
    { text: 'Create New', icon: 'mdi-pencil' },
    { text: 'Upload File', icon: 'mdi-file-upload' }
  ]
  public selectedItem: number = -1
  public fileData?: DSVRowArray<string>[]

  get sortedInvestigations() {
    return this.investigations.sort((a, b) => new Date(b.userUpdated).getTime() - new Date(a.userUpdated).getTime())
  }

  async handleFilesUploaded(files: CustomFile[]) {
    const fileData = await getCsvOrExcelData(files)
    if (fileData != null) {
      const { data, name } = fileData
      this.fileData = data
      this.newName = name
    }
  }

  addInvestigation(name: string) {
    this.$store.dispatch('addInvestigation', { name })
  }

  addInvestigationFromFile() {
    if (this.fileData != null) {
      this.$store.dispatch('addInvestigationFromFile', { name: this.newName, data: this.fileData })
    }
  }

  startEditingName(index: number) {
    this.editingNameIndex = index
    this.editingName = this.investigations[index].name
  }

  rename(index: number) {
    this.$store.dispatch('renameInvestigation', { index, name: this.editingName })
    this.editingNameIndex = -1
    this.editingName = ''
  }

  enterInvestigation(index: number) {
    this.$store.dispatch('enterInvestigation', { index })
  }

  setUpDelete(index: number) {
    this.targetIndex = index
    if (!this.investigationDeletionConfirmations) {
      this.deleteInvestigation()
    } else {
      this.deleteDialog.show = true
    }
  }

  deleteDialogShowChanged(value: boolean) {
    this.deleteDialog.show = value
  }

  deleteInvestigation() {
    if (this.targetIndex === -1) return
    this.$store.dispatch('deleteInvestigation', { index: this.targetIndex })
  }

  setUpShare(investigation: Investigation) {
    this.showShare = true
    this.targetInvestigation = investigation
  }

  async shareInvestigation(username: string) {
    const successfullyShared = await this.$store.dispatch('shareInvestigation', { investigation: this.targetInvestigation, username })
    if (successfullyShared) {
      this.shareUsername = ''
      this.showErrorMessage = false
      this.showShare = false
    } else {
      this.showErrorMessage = true
    }
  }

  async showAlerts(investigation: Investigation) {
    this.alertsPage = 1
    this.targetInvestigation = investigation
    await Promise.all([
      this.$store.dispatch('getInvestigationAlerts', {
        investigation: investigation._id,
        page: this.alertsPage,
        perPage: this.alertsPerPage
      }),
      this.$store.dispatch('getWatchlist', {
        investigation: investigation._id
      }),
    ])
    this.showAlertsDialog = true
  }

  async onAlertsPageUpdated(page: number) {
    this.alertsPage = page
    if (this.targetInvestigation != null) {
      await this.$store.dispatch('getInvestigationAlerts', {
        investigation: this.targetInvestigation._id,
        page: this.alertsPage,
        perPage: this.alertsPerPage
      })
    }
  }

  async onAlertsPerPageUpdated(perPage: number) {
    this.alertsPage = 1
    this.alertsPerPage = perPage
    if (this.targetInvestigation != null) {
      await this.$store.dispatch('getInvestigationAlerts', {
        investigation: this.targetInvestigation._id,
        page: this.alertsPage,
        perPage: this.alertsPerPage
      })
    }
  }

  public async addToWatchlist() {
    const { address, network, cluster } = this.toWatchlist
    const investigation = this.targetInvestigation ? this.targetInvestigation._id : undefined
    const result = await this.$store.dispatch('checkAndAddToWatchlist', {
      address,
      network,
      cluster,
      investigation
    })
    if (result != null && typeof result === 'string') {
      this.toWatchlist.error = result
    } else {
      this.toWatchlist.address = ''
    }
  }

  public async deleteWatchlistAddress(watchlistAddress: WatchlistAddress) {
    this.$store.dispatch('deleteFromWatchlist', watchlistAddress)
  }

  public async deleteAlert(alert: WatchlistAlert) {
    const { _id, investigation } = alert
    await this.$store.dispatch('deleteAlert', { _id })
    this.$store.dispatch('getInvestigationAlerts', {
      investigation,
      page: this.alertsPage,
      perPage: this.alertsPerPage
    })
  }

  formatDateString(date: string) {
    if (date != null) return this.$store.state.formatDate(new Date(date).getTime(), true, 1)
    return 'no date (error)'
  }

  getImage(state?: InvestigationState) {
    if (state != null) {
      return URL.createObjectURL(state.snapshot)
    }
    return '' //TODO: find placeholder
  }

  nodesShownText(state?: InvestigationState) {
    let count = 0
    if (state != null) {
      count = filter(state.nodes, n => !(classifyNodeId(n.id).type === 'transaction' || n.deleted)).length
    }
    return `${count} ${count === 1 ? 'entity' : 'entities'}`
  }

  created() {
    this.$store.dispatch('getInvestigations', { username: this.$store.state.username })
  }
}
