
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class Explorer extends Vue {
  created() {
    this.$store.dispatch('setSection', 'explorer')
  }

  mounted() {
    this.$store.dispatch('updateSnackbar', {
      show: false
    })
  }
}
