
import { Component, Vue } from 'vue-property-decorator'
import CaseManagement from '@/components/investigations/CaseManagement.vue'
import Dashboard from '@/components/investigations/Dashboard.vue'
import { mapState } from 'vuex'
import { Investigation } from '@/store/investigations/investigations'

@Component({
  components: {
    CaseManagement,
    Dashboard
  },
  computed: mapState(['currentInvestigation'])
})
export default class Investigations extends Vue {
  public currentInvestigation!: Investigation | undefined

  created() {
    this.$store.dispatch('setSection', 'investigations')
  }

  mounted() {
    this.$store.dispatch('updateSnackbar', {
      show: false
    })
  }
}
