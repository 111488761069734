
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class NetworkSection extends Vue {
  created() {
    this.$store.dispatch('setSection', 'network')
  }

  mounted() {
    this.$store.dispatch('updateSnackbar', {
      show: false
    })
  }
}
